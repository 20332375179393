import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/nl-be';
moment.locale('nl-be');
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { TranslatorService } from '../services/translator.service';

const keywordAt = 'om';
const keywordToday = 'vandaag';
const today = moment();

@Pipe({
    name: 'formatTs',
    standalone: false
})
export class formatTsPipe implements PipeTransform {
    constructor(private TranslatePipe: TranslatePipe) {}
    transform(ts: any, format: string, unix?: boolean): string {
        let momentObj = !unix ? moment(ts) : moment.unix(ts);

        if (!ts || !format || !momentObj.isValid()) {
            return ts || '-';
        }
        switch (format) {
            case 'prettyTs':
                return momentObj.calendar(null, {
                    sameDay: `[${this.TranslatePipe.transform('misc_today')}]`,
                    nextDay: `[${this.TranslatePipe.transform('misc_tomorrow')}]`,
                    nextWeek: 'DD-MM-YYYY',
                    lastDay: `[${this.TranslatePipe.transform('misc_yesterday')}]`,
                    lastWeek: 'DD-MM-YYYY',
                    sameElse: 'DD-MM-YYYY'
                });
            case 'prettyDateTime':
                return momentObj.calendar(null, {
                    sameDay: `[${this.TranslatePipe.transform('misc_today')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    nextDay: `[${this.TranslatePipe.transform('misc_tomorrow')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    nextWeek: `D MMMM YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`,
                    lastDay: `[${this.TranslatePipe.transform('misc_yesterday')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    lastWeek: `D MMMM YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`,
                    sameElse: `D MMMM YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`
                });
            case 'dayShorthand':
                return momentObj.format('ddd').replace('.', '');
            case 'monthShorthand':
                return momentObj.format('MMM').replace('.', '');
            case 'monthDayShorthand':
                if (momentObj.format('YYYY') === moment().year().toString()) {
                    return momentObj.format('D MMMM').replace('.', '');
                } else {
                    return momentObj.format('D MMMM YYYY').replace('.', '');
                }
            default:
                break;
        }
        return moment(ts).format(format);
    }
}

@Pipe({
    name: 'callback',
    pure: false,
    standalone: false
})
export class callbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any, data?: any) => boolean, data?): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter((item) => callback(item, data));
    }
}

@Pipe({
    name: 'translate',
    standalone: false
})
export class TranslatePipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) {}
    public transform(key: string): string {
        return this.TranslatorService.getTranslation(key);
    }
}

@Pipe({
    name: 'email-obfuscation',
    standalone: false
})
export class emailObfuscationPipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) {}
    public transform(key: string): string {
        return key.replace('{at}', '@').replace('{dot}', '.');
    }
}

@Pipe({
    name: 'safe',
    standalone: false
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}
