import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'lib-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: false
})
export class ButtonComponent {
    @Input() text: string = '';
    @Input() classes: string = '';
    @Input() disabled?: boolean = false;
    @Input() linkHref?: boolean = false;
    @Output() emitClick = new EventEmitter();

    constructor() {}
}
