import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
    standalone: false
})
export class ServicesComponent implements OnInit {
    phone: string;
    config: any = null;
    constructor(
        private helperservice: HelperService,
        private ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.phone = this.helperservice.getPhoneNumber();
        this.config = this.ConfigService.getConfig();
    }
}
