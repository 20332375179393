import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-arrangement',
    templateUrl: './arrangement.component.html',
    styleUrls: ['./arrangement.component.scss'],
    standalone: false
})
export class ArrangementComponent implements OnInit {
    config: any = {};
    constructor(private ConfigService: ConfigService) {}

    ngOnInit(): void {
        this.config = this.ConfigService.getConfig();
    }
}
