import { Component, Input } from '@angular/core';

@Component({
    selector: 'lib-service-card-link',
    templateUrl: './service-card-link.component.html',
    styleUrls: ['./service-card-link.component.scss'],
    standalone: false
})
export class ServiceCardLinkComponent {
    @Input() info: any;

    updated: boolean = false;
    constructor() {}
}
