import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

declare let gtag: Function;

@Component({
    selector: 'app-cookie-popup',
    templateUrl: './cookie-popup.component.html',
    styleUrls: ['./cookie-popup.component.scss'],
    standalone: false
})
export class CookiePopupComponent implements OnInit {
    status: string = '';
    GTAG: string = '';
    hidden: boolean = false;

    constructor(
        public Router: Router,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private ConfigService: ConfigService
    ) {
        this.GTAG = this.ConfigService.getConfig().GTAG;
        setTimeout(() => {
            this.Router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    if (this.status === 'accepted' && this.GTAG) {
                        gtag('config', this.GTAG, {
                            page_path: event.urlAfterRedirects
                        });
                    }
                }
            });
        }, 1);
    }

    ngOnInit(): void {
        this.status = localStorage.getItem('cookies');
        if (this.status) {
            this.hidden = true;
        }
        if (this.status === 'accepted') {
            this.addAnalyticsScript();
            // gtag('js', new Date());
            // gtag('config', 'G-TFJ6MZ8X49');
        }
    }

    accept() {
        localStorage.setItem('cookies', 'accepted');
        this.addAnalyticsScript();
        this.status = 'accepted';
        // gtag('js', new Date());
        // gtag('config', 'G-TFJ6MZ8X49');
    }

    decline() {
        localStorage.setItem('cookies', 'declined');
        this.status = 'declined';
    }

    addAnalyticsScript() {
        const scriptElement = this.loadJsScript(`https://www.googletagmanager.com/gtag/js?id=${this.GTAG}`);
        scriptElement.onload = () => {
            window['dataLayer'] = window['dataLayer'] || [];
            window['gtag'] = function () {
                window['dataLayer'].push(arguments);
            };
            if (this.GTAG) {
                gtag('js', new Date());
                gtag('config', this.GTAG);
            }
        };
    }

    loadJsScript(src: string): HTMLScriptElement {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        this.renderer.appendChild(this.document.body, script);
        return script;
    }
}
