import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, throwError } from 'rxjs';
import pkg from '../../../package.json';
import { ConfigService } from '../services/config.service';

export const ApiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const toastrService = inject(ToastrService);
    const configService = inject(ConfigService);
    const version: any = `${pkg.version}-${'nl'}`;

    let clonedRequest = request.clone({
        headers: request.headers.append('Accept-Language', 'nl')
    });

    clonedRequest = clonedRequest.clone({
        headers: clonedRequest.headers.append('captcha-token', configService.getConfig().token)
    });

    if (clonedRequest.method == 'GET') {
        clonedRequest = clonedRequest.clone({
            setParams: {
                v: version
            }
        });
    }
    return next(clonedRequest).pipe(
        catchError((error) => {
            if (checkNoNetworkConnection(error)) {
                toastrService.error('No network connection. Please try again later.');
                const error = new Error('No network connection');
                console.error(error);
            }
            switch (error.status) {
                case 400:
                    break;
                case 401:
                    break;
                case 500:
                    failwhale(error);
                    break;
                case 403:
                    failwhale(error);
                    break;
                case 404:
                    failwhale(error);
                    break;
            }
            return throwError(() => error);
        })
    );
};

const failwhale = (error: HttpErrorResponse) => {
    const msg = `${error.error}`;
    if (document.getElementsByClassName('page') && document.getElementsByClassName('page').length) {
        document.getElementsByClassName('page')[0].innerHTML = msg;
    } else {
        document.getElementsByTagName('BODY')[0].innerHTML = msg;
    }
    // modals
    if (document.getElementsByClassName('modal-backdrop') && document.getElementsByClassName('modal-backdrop').length) {
        document.getElementsByClassName('modal-backdrop')[0].setAttribute('style', 'display:none !important');
    }
    if (document.getElementsByClassName('modal') && document.getElementsByClassName('modal').length) {
        document.getElementsByClassName('modal')[0].setAttribute('style', 'display:none !important');
    }
    if (document.getElementsByClassName('modal-open') && document.getElementsByClassName('modal-open').length) {
        document.getElementsByClassName('modal-open')[0].setAttribute('style', 'overflow:unset !important');
    }
    // footer
    if (document.getElementsByClassName('footer') && document.getElementsByClassName('footer').length) {
        document.getElementsByClassName('footer')[0].setAttribute('style', 'display:none !important');
    }
};

const checkNoNetworkConnection = (error: any): boolean => {
    return (
        error instanceof HttpErrorResponse &&
        !error.headers.keys().length &&
        !error.ok &&
        !error.status &&
        !error.error.loaded &&
        !error.error.total
    );
};
