import { Component, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { HelperService } from '../../../services/helper.service';

@Component({
    selector: 'lib-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: false
})
export class NavigationComponent implements OnInit {
    @Input() img: string = '';
    @Input() name: string = '';
    @Input() phone: string = '';
    @Input() redirectTitle: string = '';
    externeLink: string = '';

    mobileMenuOpen: boolean = false;
    mobileMenu: boolean = false;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // event.target.innerWidth
        this.mobileMenuCheck();
    }

    constructor(
        private renderer: Renderer2,
        private helperService: HelperService
    ) {}

    ngOnInit(): void {
        this.externeLink = this.helperService.getExterneLink();
        this.mobileMenuCheck();
    }

    mobileMenuCheck() {
        if (window.innerWidth <= 900) {
            this.mobileMenu = true;
        } else {
            this.mobileMenu = false;
            this.toggleMobileMenu(false);
        }
    }

    toggleMobileMenu(value: boolean) {
        this.mobileMenuOpen = value;
        if (!value) {
            this.renderer.removeClass(document.body, 'mobile-menu-open');
        } else {
            this.renderer.addClass(document.body, 'mobile-menu-open');
            window.scrollTo(0, 0);
        }
    }
}
