import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultService } from 'src/app/utils/api';
import { Deceaseds } from '../../components/models/deceased';
import { Services } from '../../components/models/service';
import { ConfigService } from '../../services/config.service';
import { HelperService } from '../../services/helper.service';
import { TranslatePipe } from '../../utils/pipes';

declare const grecaptcha: any;
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit {
    phone: string = '';
    services: Services[] = [];
    deceasedName: string = '';
    deceased: Deceaseds[] = [];
    ready: boolean = false;
    bgImage: string = '';
    logoImage: string = '';
    backgroundPositioning: string = '';

    constructor(
        private helpservice: HelperService,
        private ConfigService: ConfigService,
        private DefaultService: DefaultService,
        private router: Router,
        private TranslatePipe: TranslatePipe
    ) {}

    ngOnInit(): void {
        this.backgroundPositioning = this.ConfigService.getConfig().homeBackgroundPositionY;
        this.bgImage = this.ConfigService.getConfig().homeImage;
        this.logoImage = this.ConfigService.getConfig().logoImage;
        this.phone = this.helpservice.getPhoneNumber();
        this.services.push(
            new Services(
                'assets/shared/img/misc/service_before_funeral.png',
                this.TranslatePipe.transform('before-funeral-title'),
                this.TranslatePipe.transform('before-funeral-text'),
                '/diensten/voor-de-uitvaart'
            )
        );
        this.services.push(
            new Services(
                'assets/shared/img/misc/service_during_funeral.png',
                this.TranslatePipe.transform('during-funeral-title'),
                this.TranslatePipe.transform('during-funeral-text'),
                '/diensten/tijdens-de-uitvaart'
            )
        );
        this.services.push(
            new Services(
                'assets/shared/img/misc/service_after_funeral.png',
                this.TranslatePipe.transform('after-funeral-title'),
                this.TranslatePipe.transform('after-funeral-text'),
                '/diensten/na-de-uitvaart'
            )
        );
        this.services.push(
            new Services(
                'assets/shared/img/misc/advance_arrangement.jpeg',
                this.TranslatePipe.transform('arrangement-title'),
                this.TranslatePipe.transform('arrangement-text'),
                '/diensten/voorafregeling'
            )
        );
        // let that = this;
        // let config = that.ConfigService.getConfig();
        // grecaptcha.ready(function () {
        //     grecaptcha.execute(config.captcha_public_key, { action: 'submit' }).then(function (token) {
        // that.ConfigService.setConfig({ ...config, token: token });
        this.DefaultService.deathsGetAll('', 0, 5).subscribe((next: any) => {
            this.deceased = next.data;
            this.ready = true;
        });
        //     });
        // });
    }

    redirectToDeceaseds($event) {
        this.router.navigate(['overlijdens'], { queryParams: { term: $event }, queryParamsHandling: 'merge' });
    }
}
