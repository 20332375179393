import { Component, Input } from '@angular/core';

@Component({
    selector: 'lib-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss'],
    standalone: false
})
export class UserCardComponent {
    @Input() img: string = '';
    @Input() name: string = '';
    @Input() text: string = '';
    @Input() phone: string = '';
    @Input() email: string = '';

    constructor() {}
}
