import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { Establishments } from './components/models/establishments';
import { ConfigService } from './services/config.service';
import { HelperService } from './services/helper.service';
import { emailObfuscationPipe, TranslatePipe } from './utils/pipes';
// import ownGinkghoConfig from '../../ownGinkghoConfig.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
    phone: string;
    name: string;
    establishments: Establishments[] = [];
    hideNavigation: boolean = false;
    hideFooter: boolean = false;
    hideCaptcha: boolean = false;
    footerBackgroundImage: string = '';
    portal: string = '';

    constructor(
        private helperService: HelperService,
        private TranslatePipe: TranslatePipe,
        private TitleService: Title,
        private configService: ConfigService,
        private router: Router,
        private emailPipe: emailObfuscationPipe,
        private metaService: Meta
    ) {
        document.documentElement.style.setProperty('--primary-color', configService.getConfig().primaryColor);
        document.documentElement.style.setProperty(
            '--primary-color-border',
            configService.getConfig().primaryColorBorder
        );
        document.documentElement.style.setProperty(
            '--primary-color-hover',
            configService.getConfig().primaryColorHover
        );
        this.helperService.setPhoneNumber(configService.getConfig().phoneNumber);
        this.footerBackgroundImage = configService.getConfig().footerBackgroundImage;
        this.phone = this.helperService.getPhoneNumber();
        this.name = configService.getConfig().title;
        this.helperService.setExterneLink(configService.getConfig().externalFamilyLink);
        this.establishments = configService.getConfig().establishments;
        this.establishments.map((item) => {
            if (item.email) {
                item.email = this.emailPipe.transform(item.email);
            }
            return { ...item };
        });
    }
    ngOnInit(): void {
        this.metaService.addTag({
            name: 'description',
            content: this.configService.getConfig().metaDescription
        });
        this.portal = this.configService.getConfig().portal;
        this.router.events.subscribe(async (event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                this.handleRouteChange(route);
            }
        });
        this.configService.getHideNavigation().subscribe((next) => {
            this.hideNavigation = next;
        });
        this.configService.getHideFooter().subscribe((next) => {
            this.hideFooter = next;
        });
        this.configService.getHideCaptcha().subscribe((next) => {
            this.hideCaptcha = next;
        });
    }

    public handleRouteChange(route) {
        let title = this.name;
        // let title = "";
        let routeTitle = route?.data?.title;
        if (route?.firstChild?.data?.title) routeTitle = route.firstChild.data.title;
        if (routeTitle) {
            routeTitle = 'meta_title_' + routeTitle;
        }
        title = this.TranslatePipe.transform(routeTitle) + ' | ' + title;
        this.TitleService.setTitle(title);

        // hide navigation
        if (route?.data?.hideNavigation) {
            this.configService.setHideNavigation(true);
        } else this.configService.setHideNavigation(false);

        // hide footer
        if (route?.data?.hideFooter) {
            this.configService.setHideFooter(true);
        } else this.configService.setHideFooter(false);

        if (route?.data?.hideCaptcha) {
            document.body.classList.remove('show-captcha');
            this.configService.setHideCaptcha(false);
        } else {
            document.body.classList.add('show-captcha');
            this.configService.setHideCaptcha(true);
        }

        setTimeout(() => {
            if (route.fragment) {
                const el = document.getElementById(route.fragment);
                el && window.scrollTo(0, el.offsetTop);
            }
        }, 10);
        setTimeout(() => {
            if (route.fragment) {
                const el = document.getElementById(route.fragment);
                el && window.scrollTo(0, el.offsetTop);
            }
        }, 100);
    }
}
