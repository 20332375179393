import { Component, OnInit } from '@angular/core';
import { Users } from '../../components/models/user';
import { ConfigService } from '../../services/config.service';
import { HelperService } from '../../services/helper.service';
import { emailObfuscationPipe } from '../../utils/pipes';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    standalone: false
})
export class AboutUsComponent implements OnInit {
    phone: string;
    users: Users[] = [];
    aboutUsImage: string = '';
    aboutUsText: string = '';
    history: string[];
    backgroundPositioning: string = '';

    constructor(
        private helperservice: HelperService,
        private emailPipe: emailObfuscationPipe,
        private configService: ConfigService
    ) {}

    ngOnInit(): void {
        this.backgroundPositioning = this.configService.getConfig().aboutUsBackgroundPositionY;
        this.aboutUsImage = this.configService.getConfig().aboutUsImage;
        this.phone = this.helperservice.getPhoneNumber();
        this.users = this.configService.getConfig().users;
        this.aboutUsText = this.configService.getConfig().aboutUsText;

        this.users.map((item) => {
            item.email = this.emailPipe.transform(item.email);
            return { ...item };
        });
        this.history = this.configService.getConfig().history;
    }
}
