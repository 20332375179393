import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../../services/helper.service';

@Component({
    selector: 'lib-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {
    @Input() title: string = '';
    @Input() image: string = '';
    @Input() text: string = '';
    @Input() phone?: string;
    @Input() bgImage: string = '';
    @Input() displayContactButton: boolean = true;
    @Input() displayphone: boolean = false;
    @Input() displaySearchBar: boolean = false;
    @Input() inputModel?: string;
    @Input() classes: string;
    @Input() bgImagePosition: number;

    constructor(
        private helperService: HelperService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.phone = this.helperService.getPhoneNumber();
    }

    search($event) {
        this.router.navigate(['overlijdens'], { queryParams: { term: $event || null }, queryParamsHandling: 'merge' });
    }
}
