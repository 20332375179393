import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { DefaultService } from 'src/app/utils/api';

declare const grecaptcha: any;

@Component({
    selector: 'app-search-deceased',
    templateUrl: './search-deceased.component.html',
    styleUrls: ['./search-deceased.component.scss'],
    standalone: false
})
export class SearchDeceasedComponent {
    deceased: any = [];
    showAmount: number = 9;
    startRow: number = 0;
    term: string = '';
    amountOfRow: number;
    showMore: boolean = false;
    ready: boolean = false;
    loading: boolean = false;
    previousTerm: string = '';

    constructor(
        private DefaultService: DefaultService,
        private ConfigService: ConfigService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.route.queryParams.subscribe((params: any) => {
            this.startRow = 0;
            this.term = params.term || '';
            this.getAllDeaths();
        });
    }

    loadExtra() {
        this.startRow = this.startRow + 9;
        this.getAllDeaths();
    }

    getAllDeaths() {
        // let that = this;
        this.loading = true;
        // let config = that.ConfigService.getConfig();
        // grecaptcha.ready(function () {
        //     grecaptcha.execute(config.captcha_public_key, { action: 'submit' }).then(function (token) {
        // that.ConfigService.setConfig({ ...config, token: token });
        this.DefaultService.deathsGetAll(this.term, this.startRow, this.showAmount).subscribe((next: any) => {
            if (this.previousTerm !== this.term) {
                this.previousTerm = this.term;
                this.deceased = [];
            }
            this.deceased = this.deceased.concat(next.data);
            this.amountOfRow = next.rows;
            if (next.rows > this.showAmount + this.startRow) {
                this.showMore = true;
            } else {
                this.showMore = false;
            }
            this.ready = true;
            this.loading = false;
        });
        //     });
        // });
    }
}
