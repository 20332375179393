import { Component, Input } from '@angular/core';

@Component({
    selector: 'lib-deceased-card',
    templateUrl: './deceased-card.component.html',
    styleUrls: ['./deceased-card.component.scss'],
    standalone: false
})
export class DeceasedCardComponent {
    @Input() deceased: any;
    @Input() size: string;
    constructor() {}
}
